package tta.destinigo.talktoastro.feature_wallet.data.api

import co.touchlab.kermit.Logger
import co.touchlab.kermit.NoTagFormatter
import co.touchlab.kermit.loggerConfigInit
import co.touchlab.kermit.platformLogWriter
import tta.destinigo.talktoastro.feature_wallet.data.request.CheckCouponCodeRequest
import tta.destinigo.talktoastro.feature_wallet.data.request.CheckoutRechargePlanRequest
import tta.destinigo.talktoastro.feature_wallet.data.request.GetRazorPayRequest
import tta.destinigo.talktoastro.feature_wallet.data.request.VerifyRechargeFailRequest
import tta.destinigo.talktoastro.feature_wallet.data.request.VerifyRechargeStatusRequest
import tta.destinigo.talktoastro.feature_wallet.data.response.CheckoutRechargePlanResponse
import tta.destinigo.talktoastro.feature_wallet.data.response.RechargeInfoData
import tta.destinigo.talktoastro.feature_wallet.data.response.all_coupon.AllCouponsResponse
import tta.destinigo.talktoastro.feature_wallet.data.response.apply_coupon_response.ApplyCouponResponse
import tta.destinigo.talktoastro.feature_wallet.data.response.razorpay_order_id.GetRazorPayOrderIdResponse
import tta.destinigo.talktoastro.feature_wallet.data.response.razorpay_order_id.RazorPayOrderData
import tta.destinigo.talktoastro.feature_wallet.data.response.verify_recharge_status.VerifyRechargeData
import tta.destinigo.talktoastro.feature_wallet.data.response.verify_recharge_status.VerifyRechargeStatusResponse
import tta.destinigo.talktoastro.feature_wallet.persentation.recharge_pack.data.response.RechargePackListResponse
import io.ktor.client.call.body
import io.ktor.client.plugins.ClientRequestException
import io.ktor.client.plugins.ServerResponseException
import io.ktor.client.request.get
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.request.url
import io.ktor.utils.io.errors.IOException
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.withContext
import tta.destinigo.talktoastro.feature_wallet.data.request.CustomOrderPurchaseInfoRequest
import tta.destinigo.talktoastro.feature_wallet.data.request.CustomOrderPurchaseStatus
import tta.destinigo.talktoastro.feature_wallet.data.request.CustomOrderPurchaseSuccess
import tta.destinigo.talktoastro.feature_wallet.data.response.custom_order_parches.CusotmOrderPurchaseStatusResponse
import tta.destinigo.talktoastro.feature_wallet.data.response.custom_order_parches.CustomOrderPurchaseInfoResponse
import tta.destinigo.talktoastro.feature_wallet.data.response.custom_order_parches.CustomOrderPurchaseSuccessResponse
import tta.destinigo.talktoastro.feature_wallet.data.response.custom_order_parches.PurchaseSuccessData
import tta.destinigo.talktoastro.shared.data.CommonResponse
import tta.destinigo.talktoastro.shared.remote.KtorApi
import tta.destinigo.talktoastro.shared.remote.Resources

class WalletRechargeApi : KtorApi() {

    private val storeLogger = Logger(
        loggerConfigInit(platformLogWriter(NoTagFormatter)),
        "StoreApi"
    )

    companion object {
        private const val GET_RECHARGE_PLAN_LIST = "get-recharge-plans"
        private const val POST_RECHARGE_INFO = "wallet-recharge"
        private const val POST_CHECK_COUPON_CODE = "check-coupon-code"
        private const val POST_CANCEL_COUPON_CODE = "cancel-coupon-code"
        private const val POST_RECHARGE_STATUS_VERIFY = "recharge-status-verify"
        private const val POST_RECHARGE_STATUS_FAILED = "payment-failed"
        private const val GET_RAZORPAY_ORDER_ID = "razorpay-order-id"
        private const val GET_ALL_COUPON_CODE = "get-coupons"


        private const val CUSTOM_ORDER_PREFIX = "custom-order/"
        const val GET_CUSTOM_ORDER_PURCHASE_INFO = CUSTOM_ORDER_PREFIX + "recharge"
        const val GET_CUSTOM_ORDER_PURCHASE_STATUS = CUSTOM_ORDER_PREFIX + "recharge-status-verify"
        const val GET_CUSTOM_ORDER_PURCHASE_SUCCESS = CUSTOM_ORDER_PREFIX + "success"
    }

    private suspend fun rechargePlanApi() =  client.get {
        url( GET_RECHARGE_PLAN_LIST)
    }.body<RechargePackListResponse>()

    private suspend fun rechargeInfoApi(request: CheckoutRechargePlanRequest) = client.post {
        url(POST_RECHARGE_INFO)
        setBody(request)
    }.body<CheckoutRechargePlanResponse>()

    private suspend fun checkCouponCodeApi(request: CheckCouponCodeRequest) = client.post {
        url(POST_CHECK_COUPON_CODE)
        setBody(request)
    }.body<ApplyCouponResponse>()

    private suspend fun cancelCouponCodeApi(request: CheckCouponCodeRequest) = client.post {
        url(POST_CANCEL_COUPON_CODE)
        setBody(request)
    }.body<CommonResponse>()

    private suspend fun rechargeStatusVerifyApi(request: VerifyRechargeStatusRequest) = client.post {
        url(POST_RECHARGE_STATUS_VERIFY)
        setBody(request)
    }.body<VerifyRechargeStatusResponse>()

    private suspend fun rechargeStatusFailedApi(request: VerifyRechargeFailRequest) = client.post {
        url(POST_RECHARGE_STATUS_FAILED)
        setBody(request)
    }.body<VerifyRechargeStatusResponse>()

    private suspend fun razorpayOrderIdApi(request: GetRazorPayRequest) = client.post {
        url(GET_RAZORPAY_ORDER_ID)
        setBody(request)
    }.body<GetRazorPayOrderIdResponse>()

    private suspend fun getAllCouponCode() = client.get {
        url(GET_ALL_COUPON_CODE)
    }.body<AllCouponsResponse>()

    private suspend fun getCustomOrderPurchaseInfo(request: CustomOrderPurchaseInfoRequest) = client.post {
        url(GET_CUSTOM_ORDER_PURCHASE_INFO)
        setBody(request)
    }.body<CustomOrderPurchaseInfoResponse>()

    private suspend fun getCustomOrderPurchaseSuccess(request: CustomOrderPurchaseSuccess) = client.post{
        url(GET_CUSTOM_ORDER_PURCHASE_SUCCESS)
        setBody(request)
    }.body<CustomOrderPurchaseSuccessResponse>()

    private suspend fun getCustomOrderPurchaseStatus(request: CustomOrderPurchaseStatus) = client.post{
        url(GET_CUSTOM_ORDER_PURCHASE_STATUS)
        setBody(request)
    }.body<CusotmOrderPurchaseStatusResponse>()

    suspend fun requestRechargePlanList(): Resources<RechargePackListResponse> {
        return withContext(Dispatchers.Default) {
            try {
                val rechargePlanListResponse = rechargePlanApi()
                if (rechargePlanListResponse.status) {
                    Resources.Success(rechargePlanListResponse)
                } else {
                    throw Exception(rechargePlanListResponse.message)
                }
            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }

    suspend fun getRechargeInfo(request: CheckoutRechargePlanRequest): Flow<Resources<RechargeInfoData>> {
        return flow {
            try {
                val productResponse = rechargeInfoApi(request)
                if (productResponse.status.not()) {
                    return@flow emit(
                        Resources.Error(
                            productResponse.message ?: "API ERROR RESPONSE"
                        )
                    )
                }

                emit(Resources.Success(productResponse.data))

            } catch (apiError: Exception) {
                storeLogger.d { apiError.message.toString() }
                emit(Resources.Error(apiError.message ?: "API ERROR RESPONSE"))
            } catch (io: IOException) {
                storeLogger.d { io.message.toString() }
                emit(Resources.Error(io.message ?: "API ERROR RESPONSE"))
            }
            emit(Resources.Loading(false))
        }
    }

    suspend fun getRazorPayOrderId(request: GetRazorPayRequest): Flow<Resources<RazorPayOrderData>> {
        return flow {
            try {

                val productResponse = razorpayOrderIdApi(request)
                storeLogger.d("RazorPayOrderId -> $productResponse")
                if (productResponse.status.not()) {
                    return@flow emit(
                        Resources.Error(
                            productResponse.message ?: "API ERROR RESPONSE"
                        )
                    )
                }

                emit(Resources.Success(productResponse.data))

            } catch (apiError: Exception) {
                storeLogger.d { apiError.message.toString() }
                emit(Resources.Error(apiError.message ?: "API ERROR RESPONSE"))
            } catch (io: IOException) {
                storeLogger.d { io.message.toString() }
                emit(Resources.Error(io.message ?: "API ERROR RESPONSE"))
            } finally {
                emit(Resources.Loading(false))
            }
        }
    }

    suspend fun getVerifyRechargeStatus(request: VerifyRechargeStatusRequest): Flow<Resources<VerifyRechargeData>> {
        return flow {
            try {

                val productResponse = rechargeStatusVerifyApi(request)
                if (productResponse.status?.not() == true) {
                    return@flow emit(
                        Resources.Error(
                            productResponse.message ?: "API ERROR RESPONSE"
                        )
                    )
                }

                emit(Resources.Success(productResponse.data))

            } catch (apiError: Exception) {
                storeLogger.d { apiError.message.toString() }
                emit(Resources.Error(apiError.message ?: "API ERROR RESPONSE"))
            } catch (io: IOException) {
                storeLogger.d { io.message.toString() }
                emit(Resources.Error(io.message ?: "API ERROR RESPONSE"))
            } finally {
                emit(Resources.Loading(false))
            }
        }
    }

    suspend fun getRechargeStatusFailed(request: VerifyRechargeFailRequest): Flow<Resources<VerifyRechargeData>> {
        return flow {
            try {

                val productResponse = rechargeStatusFailedApi(request)
                if (productResponse.status?.not() == true) {
                    return@flow emit(
                        Resources.Error(
                            productResponse.message ?: "API ERROR RESPONSE"
                        )
                    )
                }

                emit(Resources.Success(productResponse.data))

            } catch (apiError: Exception) {
                storeLogger.d { apiError.message.toString() }
                emit(Resources.Error(apiError.message ?: "API ERROR RESPONSE"))
            } catch (io: IOException) {
                storeLogger.d { io.message.toString() }
                emit(Resources.Error(io.message ?: "API ERROR RESPONSE"))
            } finally {
                emit(Resources.Loading(false))
            }
        }
    }

    suspend fun checkCouponCode(request: CheckCouponCodeRequest) :Flow<Resources<ApplyCouponResponse>>{
        return flow {
            try {
                val response = checkCouponCodeApi(request)
                if (response.status.not()){
                    return@flow emit(
                        Resources.Error(
                            response.message ?: "API ERROR RESPONSE"
                        )
                    )
                }
                return@flow emit(Resources.Success(response))
            } catch (apiError: Exception) {
                storeLogger.d { apiError.message.toString() }
                emit(Resources.Error(apiError.message ?: "API ERROR RESPONSE"))
            } catch (io: IOException) {
                storeLogger.d { io.message.toString() }
                emit(Resources.Error(io.message ?: "API ERROR RESPONSE"))
            } finally {
                emit(Resources.Loading(false))
            }
        }
    }


    suspend fun removeCouponCode(request: CheckCouponCodeRequest): Flow<Resources<CommonResponse>>{
        return flow {
            try {
                val response = cancelCouponCodeApi(request)
                if (response.status.not()){
                    return@flow emit(
                        Resources.Error(response.msg ?: "API ERROR RESPONSE")
                    )
                }
                return@flow emit(Resources.Success(response))
            } catch (apiError: Exception) {
                storeLogger.d { apiError.message.toString() }
                emit(Resources.Error(apiError.message ?: "API ERROR RESPONSE"))
            } catch (io: IOException) {
                storeLogger.d { io.message.toString() }
                emit(Resources.Error(io.message ?: "API ERROR RESPONSE"))
            } finally {
                emit(Resources.Loading(false))
            }
        }
    }

    suspend fun getAllCouponCodeInfo(): Flow<Resources<AllCouponsResponse>>{
        return flow {
            try {
                val response = getAllCouponCode()
                if(response.status.not()){
                    return@flow emit(
                        Resources.Error(response.message ?: "API ERROR RESPONSE")
                    )
                }
                return@flow emit(Resources.Success(response))
            } catch (apiError: Exception) {
                storeLogger.d { apiError.message.toString() }
                emit(Resources.Error(apiError.message ?: "API ERROR RESPONSE"))
            } catch (io: IOException) {
                storeLogger.d { io.message.toString() }
                emit(Resources.Error(io.message ?: "API ERROR RESPONSE"))
            } finally {
                emit(Resources.Loading(false))
            }
        }
    }

    suspend fun getRechargeInfoCustomOrder(request: CustomOrderPurchaseInfoRequest): Flow<Resources<RechargeInfoData>>{
        return flow {
            try {
                val response = getCustomOrderPurchaseInfo(request)
                if(response.status.not()){
                    return@flow emit(
                        Resources.Error(response.message ?: "API ERROR RESPONSE")
                    )
                }
                return@flow emit(Resources.Success(response.data))
            } catch (apiError: Exception) {
                storeLogger.d { apiError.message.toString() }
                emit(Resources.Error(apiError.message ?: "API ERROR RESPONSE"))
            } catch (io: IOException) {
                storeLogger.d { io.message.toString() }
                emit(Resources.Error(io.message ?: "API ERROR RESPONSE"))
            } finally {
                emit(Resources.Loading(false))
            }
        }
    }

    suspend fun onGetCustomOrderPurchaseStatus(request: CustomOrderPurchaseStatus):Flow<Resources<VerifyRechargeData>>{
        return flow {
            try {
                val response = getCustomOrderPurchaseStatus(request)
                if(response.status.not()){
                    return@flow emit(
                        Resources.Error(response.message ?: "API ERROR RESPONSE")
                    )
                }
                return@flow emit(Resources.Success(response.data))
            } catch (apiError: Exception) {
                storeLogger.d { apiError.message.toString() }
                emit(Resources.Error(apiError.message ?: "API ERROR RESPONSE"))
            } catch (io: IOException) {
                storeLogger.d { io.message.toString() }
                emit(Resources.Error(io.message ?: "API ERROR RESPONSE"))
            } finally {
                emit(Resources.Loading(false))
            }
        }
    }

    suspend fun customOrderPaymentPurchaseSuccess(request: CustomOrderPurchaseSuccess): Flow<Resources<PurchaseSuccessData>>{
        return flow {
            try {
                val response = getCustomOrderPurchaseSuccess(request)
                if(response.status.not()){
                    return@flow emit(
                        Resources.Error(response.message ?: "API ERROR RESPONSE")
                    )
                }
                return@flow emit(Resources.Success(response.data))
            } catch (apiError: Exception) {
                storeLogger.d { apiError.message.toString() }
                emit(Resources.Error(apiError.message ?: "API ERROR RESPONSE"))
            } catch (io: IOException) {
                storeLogger.d { io.message.toString() }
                emit(Resources.Error(io.message ?: "API ERROR RESPONSE"))
            } finally {
                emit(Resources.Loading(false))
            }
        }
    }

}