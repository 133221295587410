package tta.destinigo.talktoastro.feature_wallet.persentation.recharge_checkout

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import tta.destinigo.talktoastro.feature_wallet.data.api.WalletRechargeApi
import tta.destinigo.talktoastro.feature_wallet.data.request.CheckCouponCodeRequest
import tta.destinigo.talktoastro.feature_wallet.data.request.CheckoutRechargePlanRequest
import tta.destinigo.talktoastro.feature_wallet.data.response.RechargeInfoData
import tta.destinigo.talktoastro.feature_wallet.persentation.recharge_getway.PaymentGatewayArg
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import tta.destinigo.talktoastro.feature_wallet.data.request.CustomOrderPurchaseInfoRequest
import tta.destinigo.talktoastro.feature_wallet.data.request.CustomOrderPurchaseSuccess
import tta.destinigo.talktoastro.shared.data.RechargeData
import tta.destinigo.talktoastro.shared.platform.showToast
import tta.destinigo.talktoastro.shared.remote.Resources

class RechargeCheckoutComponent(
    context: ComponentContext,
    private val _rechargeData: RechargeData?,
    private val onProceedToPayment: (PaymentGatewayArg) -> Unit,
    private val onBackPressed:()-> Unit,
    private val onShowAllCouponClick:()-> Unit,
    private val _isCustomOrder:Boolean = false,
    private val _heading:String = "",
    private val _image:String = "",
    private val customOrderConfirm:(String)->Unit,
    private val customOrderError:()->Unit,
    context1: ComponentContext
) : ComponentContext by context, KoinComponent {

    val rechargeData get() = _rechargeData
    val isCustomOrder get() = _isCustomOrder
    val heading get() = _heading
    val image get() = _image
   // val selectedCouponCode get() = _selectedCouponCode
    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)
    val api: WalletRechargeApi by inject()
    private val _state = MutableStateFlow<RechargeInfoData?>(null)
    private val _customCoupon = MutableStateFlow<Boolean?>(null)

    val customCoupon get() = _customCoupon.asStateFlow()
    private val _customCouponRemove = MutableStateFlow<Boolean?>(null)
    val customCouponRemove get() = _customCouponRemove.asStateFlow()
    private val _cashbackAmount = MutableStateFlow<String?>(null)
    val cashbackAmount get() = _cashbackAmount.asStateFlow()
//    private val _allCouponState = MutableStateFlow<AllCouponState>(AllCouponState.Loading(false))
//    val allCouponState get() = _allCouponState.asStateFlow()

//    private val _allCouponState = MutableStateFlow<List<AllCouponn>>(emptyList())
//    val allCouponState get() = _allCouponState.asStateFlow()


    val state get() = _state.asStateFlow()
    private val _isLoading = MutableStateFlow<Boolean>(true)
    private val _isSuccessFullyParches = MutableStateFlow<Boolean>(false)
    val isSuccessFullyParches get() = _isSuccessFullyParches.asStateFlow()
    val isLoading get() = _isLoading.asStateFlow()

    fun onBackPress() = onBackPressed.invoke()
    fun onShowAllCouponPress(): String {
        onShowAllCouponClick.invoke()
        return "All Coupons Loaded"
    }

    fun proceedToPayment(
        txnId: String,
        orderId: String,
        coinUsed: Int,
        cashPayment: String
    ) {
        val paymentGatewayArg = PaymentGatewayArg(
            txnId = txnId,
            orderId = orderId,
            coinUsed = coinUsed,
            cashPayment = cashPayment
        )
        //Logger.d { "proceedToPayment click in Checkout page: $cashPayment" }
        onProceedToPayment.invoke(paymentGatewayArg)
    }

    init {
        lifecycle.doOnStart {
            Logger.d { "Home Screen Tab Component onStart" }
            //Logger.d { "RechargeCheckoutComponent Component onStart $selectedCouponCode" }
            getRechargeInfo()
            if (rechargeData?.cupon != null){
                coroutineScope.launch {
                }
            }
        }

        lifecycle.doOnDestroy {
            Logger.d { "Home Screen Tab Component onDestroy" }
            coroutineScope.cancel() // Cancel all coroutines when the component is destroyed
        }
    }

//    private fun onRechargeListLoaded(list: TransationDetails) = coroutineScope.launch {
//        if (rechargeData?.cupon != null && list.txnId != null) {
//            val request = CheckCouponCodeRequest(couponId =  rechargeData!!.cupon!!.couponCode!!,txnId = list.txnId!!)
//            checkCouponCode(request)
//        }else{
//            Logger.d { "onRechargeListLoaded empty data else part" }
//        }
//    }

    private fun getRechargeInfo() {
        coroutineScope.launch {
            if(isCustomOrder){
                val request = CustomOrderPurchaseInfoRequest(txnId = _rechargeData?.id.toString())
                api.getRechargeInfoCustomOrder(request = request).collectLatest {
                    when(it){
                        is Resources.Error -> {
                            //   _state.value = Resources.Error(it.msg)
//                        _state.value = Resources.Error(it.msg)
                            Logger.d { "getRechargeInfo state Error -> ${it.msg}" }
                        }
                        is Resources.Loading -> {
                            Logger.d { "getRechargeInfo state Loading -> ${it.isLoading}" }
                        }
                        is Resources.Success -> {
                            _state.value = it.data
                            Logger.d { "getRechargeInfo state Success -> ${it.data}" }
                        }
                    }
                }
            }else {
            val otpRequest = CheckoutRechargePlanRequest(
                planId = _rechargeData?.id.toString(),
                couponCode = null
            )

                api.getRechargeInfo(otpRequest).collectLatest {
                Logger.d { "getRechargeInfo state -> $it" }
                when (it) {
                   is Resources.Error -> {
                        Logger.d { "getRechargeInfo state Error -> $it" }
//                        _state.value = Resources.Error(it.msg)
                   }
                    is Resources.Loading -> {
                        Logger.d { "getRechargeInfo state Loading -> ${it.isLoading}" }
                        _isLoading.value = it.isLoading
                    }

                    is Resources.Success -> {
                        Logger.d { "getRechargeInfo state Success -> $it" }

                        // First, update the state with the received data
                        _state.value = it.data
                        //onRechargeListLoaded(it.data!!.transationDetails)
//                        // Then, check if the coupon exists and transaction ID is not null, and call checkCouponCode
//                        if (rechargeData?.cupon != null && it.data?.transationDetails?.txnId != null) {
//                            coroutineScope.launch {
//                                checkCouponCode(
//                                    request = CheckCouponCodeRequest(
//                                        couponId = rechargeData!!.cupon!!.couponCode!!,
//                                        txnId = it.data.transationDetails.txnId
//                                    )
//                                )
//                            }
//                        }else{
//                            Logger.d { "getRechargeInfo empty data else part" }
//                        }
                    }

                    else -> Unit
                }
            }
            }
        }
    }

    fun checkCouponCode(request: CheckCouponCodeRequest){
        coroutineScope.launch {
            api.checkCouponCode(request).collectLatest {
                when(it){
                    is Resources.Error -> {
                        Logger.d{ "checkCouponCode ERROR ${it.msg}" }
                        onCouponApplyError(it.msg.toString())
                    }
                    is Resources.Loading -> {
                        _isLoading.value = it.isLoading
                    }
                    is Resources.Success -> {
                        Logger.d { "checkCouponCode ${it.data}" }
                        Logger.d { "checkCouponCode ${it.msg}" }

                        // Check if data and status are not null
                        val status = it.data?.status
                        if (status != null) {
                            _customCoupon.value = status
                            _cashbackAmount.value = it.data?.cashbackValue
                            onCouponApplySuccess(status)
                        } else {
                            //_customCoupon.value = false
                            Logger.d { "Coupon status is null." }
                        }
                    }
                }
            }

        }
    }

    fun cancelCouponCode(request: CheckCouponCodeRequest){
        coroutineScope.launch {
            api.removeCouponCode(request).collectLatest {
                when(it){
                    is Resources.Error -> {
                        Logger.d { "cancelCouponCode error: ERROR ${it.msg}" }
                        onCouponRemoveError(it.msg.toString())
//                        _customCouponRemove.value = true
//                        _cashbackAmount.value = 0.toString()
//                        onCouponRemoveSuccess(true)
                    }
                    is Resources.Loading -> {
                        Logger.d { "cancelCouponCode loading: ${it.isLoading}" }
                    }
                    is Resources.Success -> {
                        Logger.d { "cancelCouponCode success: ${it.data}" }
                        val status = it.data?.status
                        if(status != null){
                            _customCouponRemove.value = status
                            _cashbackAmount.value = 0.toString()
                            onCouponRemoveSuccess(status)
                        }
                    }
                }
            }
        }
    }

    private suspend fun enrollCustomOrder() {
        val request = CustomOrderPurchaseSuccess(
            state.value?.transationDetails?.id.toString(),
            state.value?.transationDetails?.customOrderId.toString()
        )
        api.customOrderPaymentPurchaseSuccess(request).collectLatest { result ->
            when (result) {
                is Resources.Error -> {
                    customOrderError.invoke()
                    Logger.d { "enrollCustomOrder on Error State ${result.msg}" }
                }
                is Resources.Loading -> {
                    Logger.d { "enrollCustomOrder on Loading State ${result.isLoading}" }
                }
                is Resources.Success -> {
                    customOrderConfirm.invoke(state.value?.transationDetails?.customOrderId.toString())
                    // Directly update the state flow without nested launch
//                    showToast("${result.data?.txnHistory?.lastWalletBalance}")
//                    _isSuccessFullyParches.value = true
                    Logger.d { "enrollCustomOrder on success State ${result.data}" }
                }
            }
        }
    }


//    fun getAllCouponCode(){
//        coroutineScope.launch {
//            api.getAllCouponCodeInfo().collectLatest {
//                when(it){
//                    is Resources.Error -> {
//                        Logger.d { "getAllCouponCode error: ${it.msg}" }
//                    }
//                    is Resources.Loading -> {
//                        Logger.d { "getAllCouponCode loading: ${it.isLoading}" }
//                    }
//                    is Resources.Success -> {
//                        onAllCouponLoadSuccess(it.data?.data!!)
//                        Logger.d { "getAllCouponCode success: ${it.data.data.allCouponnList}" }
//                    }
//                }
//            }
//        }
//    }
//
//
//    private fun onAllCouponLoadSuccess(data: CouponData) = coroutineScope.launch{
//        _allCouponState.value = data.allCouponnList!!
//        //_allCouponState.emit(AllCouponState.AllCouponInfoSuccess(data))
//        Logger.d { "onAllCouponLoadSuccess : ${data.allCouponnList}" }
//    }



    private fun onCouponApplySuccess(confirmation:Boolean){
        if(confirmation){
            showToast("Coupon Apply Successful")
        }
    }

    private fun onCouponApplyError(msg:String){
        if(msg.isNotEmpty()){
            showToast(msg)
        }
    }


    private fun onCouponRemoveSuccess(confirmation:Boolean){
        if(confirmation){
            showToast("Coupon Remove Successful")
        }
    }

    private fun onCouponRemoveError(msg:String){
        if(msg.isNotEmpty()){
            showToast(msg)
        }
    }


    fun callEnrollCustomOrder() = coroutineScope.launch {
        enrollCustomOrder()
    }

}