package tta.destinigo.talktoastro.feature_wallet.data.response.custom_order_parches

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CustomOrderPurchaseSuccessResponse(
    @SerialName("code") val code: Int, // 200
    @SerialName("data")val data: PurchaseSuccessData?,
    @SerialName("message") val message: String,
    @SerialName("status") val status: Boolean // true

)
