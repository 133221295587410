package tta.destinigo.talktoastro.feature_wallet.persentation.recharge_pack.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import tta.destinigo.talktoastro.shared.data.RechargeData

@Serializable
data class RechargePackListResponse(
    @SerialName("code")
    val code: Int?,
    @SerialName("data")
    val `data`: List<RechargeData>?,
    @SerialName("message")
    val message: String?,
    @SerialName("status")
    val status: Boolean
)



@Serializable
data class Cupon(
    @SerialName("cashback_percent") val cashbackPercent: Int?, // 10
    @SerialName("cashback_value") val cashbackValue: Int?, // 100
    @SerialName("coupon_code") val couponCode: String?, // New 10
    @SerialName("expiry_date") val expiryDate: String?, // 2023-10-08
    @SerialName("id") val id: Int?, // 1
    @SerialName("minimum_value") val minimumValue: Int?, // 200
    @SerialName("status") val status: Int?, // 1,
    @SerialName("coupon_text") val cuponText: String?
)