package tta.destinigo.talktoastro.feature_wallet.persentation.razorpay_gateway_new

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight

@Composable
fun StartGatewayPage(
    paymentGatewayComponent: StartGatewayComponent
) {

    Column(
        modifier = Modifier.fillMaxSize(),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
    ) {
        Text(
            text = "Processing...",
            style = MaterialTheme.typography.titleLarge.copy(
                color = Color.Black,
                fontWeight = FontWeight.Bold
            )
        )
        Text(
            text = "Please don't press back",
            style = MaterialTheme.typography.titleSmall
        )
        CircularProgressIndicator(
            modifier = Modifier
        )
    }
//    Box(
//        modifier = Modifier.fillMaxSize()
//    ) {
//
//
//    }
}