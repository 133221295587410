package tta.destinigo.talktoastro.feature_wallet.data.response


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RechargeInfoData(
    @SerialName("referbalance")
    val referbalance: Referbalance,
    @SerialName("transation_details")
    val transationDetails: TransationDetails
)