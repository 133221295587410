package tta.destinigo.talktoastro.feature_wallet.data.response.all_coupon

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import tta.destinigo.talktoastro.feature_wallet.data.response.all_coupon.AllCouponn

@Serializable
data class CouponData(
    @SerialName("AllCouponnList") val allCouponnList: List<AllCouponn>?
)
