package tta.destinigo.talktoastro.feature_wallet.data.request

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class GetRazorPayRequest(
    @SerialName("txn_id")val taxnId:String,
    @SerialName("redeem")val isCoinUsed:Int,
    @SerialName("domain") val domain : String,
    @SerialName("platformtype") val platformType:String
)