package tta.destinigo.talktoastro.feature_wallet.persentation.all_coupons_page

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import tta.destinigo.talktoastro.feature_wallet.data.CouponApplyListener
import tta.destinigo.talktoastro.feature_wallet.data.response.all_coupon.Coupon
import tta.destinigo.talktoastro.shared.theme.Scale
import tta.destinigo.talktoastro.shared.theme.primaryColor

@Composable
fun CouponCard(data: Coupon, listener: CouponApplyListener, onDismiss: () -> Unit) {
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .background(Color.White)
            .padding(horizontal = 12.dp, vertical = 12.dp),
        elevation = CardDefaults.cardElevation(4.dp)
    ) {
        Column(
            modifier = Modifier.padding(16.dp)
        ) {
            Text(
                text = data.couponCode,
                style = TextStyle(fontSize = 15.sp, fontWeight = FontWeight.Bold)
            )
            Spacer(modifier = Modifier.height(3.dp))
            Text(
                text = data.couponText,
                style = TextStyle(fontSize = 12.sp),
                maxLines = 3,
                overflow = TextOverflow.Ellipsis
            )
            Spacer(modifier = Modifier.height(8.dp))
            HorizontalDivider(
                modifier = Modifier
                    .height(1.dp)
                    .padding(end = 10.dp)
                //color = MaterialTheme.colors.onSurface.copy(alpha = 0.12f)
            )
            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.End,
                verticalAlignment = Alignment.CenterVertically
            ) {
                Button(
                    onClick = {
                        listener.onCouponApplied(data.couponCode)
                        onDismiss.invoke()
                    },
                    colors = ButtonDefaults.buttonColors(primaryColor),
                    shape = RoundedCornerShape(Scale.CORNER_EXTRA_SMALL),
                    elevation = ButtonDefaults.elevatedButtonElevation(Scale.ELEVATION_SMALL),
                ) {
                    Text(
                        text = "Apply",
                        color = Color.White,
                        textAlign = TextAlign.Center
                    )
                }
            }
        }
    }
}
