package tta.destinigo.talktoastro.feature_wallet.data.request

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CustomOrderPurchaseSuccess(
    @SerialName("txn_id")val txnId: String,
    @SerialName("custom_order_id")val orderId: String
)
