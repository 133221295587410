package tta.destinigo.talktoastro.feature_wallet.data.request

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CheckoutRechargePlanRequest(
    @SerialName("plan_id")val planId:String,
    @SerialName("coupon_code")val couponCode:String?
)
