package tta.destinigo.talktoastro.feature_wallet.persentation.recharge_payment_success

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Text
import androidx.compose.material3.TopAppBar
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowBack
import androidx.compose.material.icons.filled.Done
import androidx.compose.material.icons.filled.Wallet
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.resources.painterResource
import tta.destinigo.talktoastro.shared.local.UserInfoProvider
import tta.destinigo.talktoastro.shared.theme.background_color
import tta.destinigo.talktoastro.shared.theme.initializeCurrency
import tta.destinigo.talktoastro.shared.theme.md_them_primary

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun RechargeSuccess(
    rechargeSuccessComponent: RechargeSuccessComponent
) {

    Scaffold(
        topBar = {
//            Text(text = "Edit Profile")?
            TopAppBar(
                title = { },
//                colors = TopAppBarDefaults.topAppBarColors(background_color),
                navigationIcon = {
                    Icon(
                        imageVector = Icons.AutoMirrored.Filled.ArrowBack, // Use the correct resource ID
                        contentDescription = null,
                        modifier = Modifier.clickable {
                            rechargeSuccessComponent.onBackPress()
                        }
                            .size(40.dp)
                            .padding(start = 10.dp)
                            .clip(CircleShape).clickable { }, // Clip image to circle
                        tint = Color.Black // Adjust tint color as needed
                    )
                }
            )
        }
    ) {
        Column(modifier = Modifier
            .fillMaxSize()
            .background(color = background_color)
        ) {
            RechargeFailureScreen(
                topUpAmount = rechargeSuccessComponent.topUpAmount,
                onDoneClick = {
                    rechargeSuccessComponent.onDone()
                },
                onRechargeAgainClick = {
                    rechargeSuccessComponent.onRechargeMore()
                }
            )
        }
    }
}


@Composable
fun RechargeFailureScreen(
    topUpAmount: String,
    onDoneClick: () -> Unit,
    onRechargeAgainClick: () -> Unit
) {
    Column(
        modifier = Modifier
            .fillMaxSize()
            .padding(16.dp),
        verticalArrangement = Arrangement.SpaceBetween,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Column(
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = Alignment.CenterHorizontally,
            modifier = Modifier.weight(1f)
        ) {
            Icon(
                imageVector = Icons.Default.Done,
                contentDescription = null,
                modifier = Modifier.padding(bottom = 16.dp).size(16.dp)
            )
//            Icon(
//                painter = painterResource(Res.drawable.done_icon),
//                contentDescription = null,
//                modifier = Modifier.padding(bottom = 16.dp)
//            )
            Text(
                text = "Top Up Success!",
            )
            Text(
                text = "Your top up has been successfully done.",
                modifier = Modifier.padding(bottom = 16.dp),
                maxLines = 2
            )
            Text(
                text = "Total top-up",
                modifier = Modifier
            )

            Text(
                text = "${initializeCurrency()} $topUpAmount",
                modifier = Modifier.padding(bottom = 16.dp)
            )
        }
        Column(
            verticalArrangement = Arrangement.Bottom,
            horizontalAlignment = Alignment.CenterHorizontally,
            modifier = Modifier.fillMaxWidth()
        ) {
            Text(
                text = "Your wallet balance is now",
                modifier = Modifier.padding(bottom = 4.dp)
            )
            Box(
                modifier = Modifier
                    .background(
                        Color(0xFFFFFFFFFF),
                        shape = RoundedCornerShape(8.dp)
                    ) // Change the color as needed
            ) {
                Row(
//                    verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier.padding(10.dp)
                ) {
                    Icon(
                        imageVector = Icons.Default.Wallet,
                        contentDescription = null,
                        modifier = Modifier.padding(end = 8.dp)
                    )
                    Spacer(modifier = Modifier.width(8.dp))
                    Column {
                        Text(
                            text = "Wallet Balance",
                            modifier = Modifier.padding(bottom = 2.dp)
                        )
                        Text(
                            text = "${initializeCurrency()}${UserInfoProvider.getTotalWalletMoney().toString()}",
                            modifier = Modifier.padding(bottom = 2.dp)
                        )
                    }
                }
            }
            Spacer(modifier = Modifier.height(24.dp))

            Button(
                onClick = onDoneClick,
                modifier = Modifier
                    .fillMaxWidth()
                    .height(55.dp)
                    .padding(bottom = 8.dp),
                shape = RoundedCornerShape(5.dp), // Set the shape to RectangleShape
                colors = ButtonDefaults.buttonColors(md_them_primary)
            ) {
                Text(text = "Done", color = Color.White)
            }

            Button(
                onClick = onRechargeAgainClick,
                modifier = Modifier
                    .fillMaxWidth()
                    .height(55.dp)
                    .padding(bottom = 8.dp),
                shape = RoundedCornerShape(5.dp), // Set the shape to RoundedCornerShape
                colors = ButtonDefaults.buttonColors(Color.Transparent),
                border = BorderStroke(
                    1.dp,
                    Color.Black
                ) // Add border with specified width and color
            ) {
                Text(text = "Top up Money Again", color = md_them_primary)
            }
        }
    }
}



