package tta.destinigo.talktoastro.feature_wallet.persentation.recharge_getway


import kotlinx.serialization.Serializable

@Serializable
data class PaymentGatewayArg(
    val txnId:String,
    val coinUsed:Int,
    val orderId:String,
    val cashPayment:String
)
