package tta.destinigo.talktoastro.feature_wallet.persentation.recharge_pack

import androidx.compose.animation.animateColorAsState
import androidx.compose.animation.core.animateDpAsState
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.foundation.lazy.grid.itemsIndexed
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Wallet
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import tta.destinigo.talktoastro.feature_wallet.data.api.WalletRechargeApi
import tta.destinigo.talktoastro.feature_wallet.persentation.recharge_pack.data.response.RechargePackListResponse
import tta.destinigo.talktoastro.shared.data.RechargeData
import tta.destinigo.talktoastro.shared.local.UserInfoProvider
import tta.destinigo.talktoastro.shared.persentation.CommonTopAppBar
import tta.destinigo.talktoastro.shared.persentation.LoadingProgressDialog
import tta.destinigo.talktoastro.shared.platform.showToast
import tta.destinigo.talktoastro.shared.remote.Resources
import tta.destinigo.talktoastro.shared.theme.Scale
import tta.destinigo.talktoastro.shared.theme.Scale.WINDOW_WIDTH_SIZE_COMPACT
import tta.destinigo.talktoastro.shared.theme.Scale.WINDOW_WIDTH_SIZE_ELSE
import tta.destinigo.talktoastro.shared.theme.Scale.WINDOW_WIDTH_SIZE_EXPEND
import tta.destinigo.talktoastro.shared.theme.Scale.WINDOW_WIDTH_SIZE_MEDIUM
import tta.destinigo.talktoastro.shared.theme.background_color
import tta.destinigo.talktoastro.shared.theme.initializeCurrency
import tta.destinigo.talktoastro.shared.theme.primaryColor

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class, ExperimentalMaterial3Api::class)
@Composable
fun ChooseRechargePack(
    component: ChoseRechargePackComponent
) {
//    val rechargePackState = remember {

    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val widthModifier = when (windowSizeClass) {
        WindowWidthSizeClass.Compact -> WINDOW_WIDTH_SIZE_COMPACT
        WindowWidthSizeClass.Medium -> WINDOW_WIDTH_SIZE_MEDIUM
        WindowWidthSizeClass.Expanded -> WINDOW_WIDTH_SIZE_EXPEND
        else -> WINDOW_WIDTH_SIZE_ELSE
    }

    val rechargePackState = rememberSaveable {
        mutableStateOf<Resources<RechargePackListResponse>>(Resources.Loading())
    }

    LaunchedEffect(key1 = true) {
        rechargePackState.value = WalletRechargeApi().requestRechargePlanList()
    }

    Box(
        modifier = Modifier
            .fillMaxSize()
            .background(background_color)
            .padding(start = 16.dp, end = 16.dp, bottom = 16.dp, top = 0.dp),
        contentAlignment = Alignment.Center
    ) {
        // Directly place the ToolBar
        Column(
            modifier = widthModifier
                .fillMaxSize()
                .background(background_color)
        ) {

            CommonTopAppBar(title = "Wallet Recharge", onBackClick = {component.onBackPress()}, modifier = Modifier)
//            ToolBar(
//                title = "Wallet Recharge",
//                icon = Icons.AutoMirrored.Filled.ArrowBack,
//                onIconClick = { component.onBackPress() }
//            )

            ///Spacer(modifier = Modifier.height(20.dp)) // Adding space below the ToolBar

            // Your content inside BoxWithConstraints
            BoxWithConstraints(
                modifier = Modifier
                    .fillMaxSize()
                    .background(background_color)
            ) {
                val maxWidth = maxWidth

                Column(
                    modifier = Modifier.fillMaxSize()
                ) {
                    when (val state = rechargePackState.value) {
                        is Resources.Error -> {
                            showToast(state.msg ?: "Unexpected Error Happening")
                        }

                        is Resources.Loading -> {
                            if (state.isLoading) LoadingProgressDialog { }
                        }

                        is Resources.Success -> {
                            val rechargePackList = state.data?.data

                            ChooseRechargePackScreen(
                                walletMoney = UserInfoProvider.getTotalWalletMoney().toString(),
                                recommendedRechargePacks = rechargePackList!!,
                                onRechargeButtonClick = { rechargePack ->
                                    rechargePack?.let {
                                        component.onSelectRechargePack(rechargePack)
                                    }
                                }
                            )
                        }
                    }
                }
            }
        }

    }
}
//}

//@Composable
//fun ChooseRechargePack(
//    component: ChoseRechargePackComponent
//) {
//    val rechargePackState =
//        rememberSaveable() { mutableStateOf<Resources<RechargePackListResponse>>(Resources.Loading()) }
//
//    LaunchedEffect(key1 = true) {
//        rechargePackState.value = WalletRechargeApi().requestRechargePlanList()
//    }
//
//
//    Scaffold(topBar = {
//        ToolBar("Wallet Recharge", Icons.AutoMirrored.Filled.ArrowBack, onIconClick = {
//            component.onBackPress()
//        })
//    }) {
//
//        BoxWithConstraints(
//            modifier = Modifier
//                .fillMaxSize()
//                .background(background_color)
//        ) {
//            val maxWidth = maxWidth
//
//            Column(
//                modifier = Modifier.fillMaxSize().padding(
//                    horizontal = if (maxWidth < 600.dp) 16.dp else 32.dp, vertical = 24.dp
//                ).widthIn(max = 800.dp) // Set a maximum width for larger screens
////                    .align(Alignment.CenterHorizontally) // Center align for larger screens
//            ) {
//
//                when (val state = rechargePackState.value) {
//                    is Resources.Error -> {
//                        showToast(state.msg ?: "Unexpected Error Happening")
//                    }
//
//                    is Resources.Loading -> {
//                        if (state.isLoading) LoadingProgressDialog { }
//                    }
//
//                    is Resources.Success -> {
//                        val rechargePackList = state.data?.data
//
//                        ChooseRechargePackScreen(walletMoney = UserInfoProvider.getTotalWalletMoney()
//                            .toString(),
//                            recommendedRechargePacks = rechargePackList!!,
//                            onRechargeButtonClick = { rechargePack ->
//                                rechargePack?.let {
//                                    component.onSelectRechargePack(rechargePack)
//                                }
//                            })
//                    }
//                }
//            }
//        }
//
//    }
//
//}

//@Composable
//fun ChooseRechargePack(
//    component: ChoseRechargePackComponent
//) {
//
//    val rechargePackState =
//        rememberSaveable() { mutableStateOf<Resources<RechargePackListResponse>>(Resources.Loading()) }
//
//    LaunchedEffect(key1 = true) {
//        rechargePackState.value = WalletRechargeApi().requestRechargePlanList()
//    }
//
//    Column(modifier = Modifier.background(background_color)) {
//
//        ToolBar("Wallet Recharge", Icons.AutoMirrored.Filled.ArrowBack, onIconClick = {
//            component.onBackPress()
//        })
//
//        when (val state = rechargePackState.value) {
//            is Resources.Error -> {
//                showToast(state.msg ?: "Unexpected Error Happening")
//            }
//
//            is Resources.Loading -> {
//                if (state.isLoading) LoadingProgressDialog {  }
//            }
//
//            is Resources.Success -> {
//
//                val rechargePackList = state.data?.data
//
//                ChooseRechargePackScreen(
//                    walletMoney = UserInfoProvider.getTotalWalletMoney().toString(),
//                    recommendedRechargePacks = rechargePackList!!,
//                    onRechargeButtonClick = { rechargePack ->
//                        rechargePack?.let {
//                            component.onSelectRechargePack(rechargePack)
//                        }
//                    }
//                )
//
//            }
//        }
//
//    }
//}


@Composable
fun ToolBar(
    title: String, icon: ImageVector, onIconClick: () -> Unit
) {
    Row(
        modifier = Modifier.fillMaxWidth().padding(vertical = 10.dp,horizontal = 16.dp).background(
            background_color),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Icon(imageVector = icon,
            contentDescription = null,
            tint = Color.Black,
            modifier = Modifier.padding(start = 4.dp).clickable { onIconClick() })
        Text(
            text = title,
            modifier = Modifier.weight(1f).padding(start = 16.dp),
            color = Color.Black,
            fontSize = 18.sp,
            maxLines = 1,
//            overflow = TextOverflow.Ellipsis,
            fontWeight = FontWeight.Bold
        )
    }
    //Spacer(modifier = Modifier.padding(bottom = 10.dp))
}


@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun ChooseRechargePackScreen(
    walletMoney: String,
    recommendedRechargePacks: List<RechargeData>,
    onRechargeButtonClick: (RechargeData?) -> Unit,
) {
    var selectedRechargeAmount by rememberSaveable { mutableStateOf<RechargeData?>(null) }

    Box(
        modifier = Modifier.fillMaxSize().background(background_color)
            //.padding(20.dp)
    ) {

        Spacer(modifier = Modifier.width(8.dp))

        Column(
            modifier = Modifier.fillMaxSize()
                .padding(bottom = 56.dp) // Adjust bottom padding to leave space for the button
        ) {
            Box(
                modifier = Modifier
                    .clip(RoundedCornerShape(Scale.CORNER_SMALL))
                    .background(color = primaryColor).padding(20.dp),
                contentAlignment = Alignment.TopStart
            ) {
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.SpaceBetween,
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Icon(
                        imageVector = Icons.Default.Wallet,
                        contentDescription = null,
                        tint = Color.White,
                        modifier = Modifier.size(24.dp)
                    )
                    Column {
                        Text(
                            text = "${initializeCurrency()} $walletMoney", color = Color.White, fontSize = 20.sp
                        )
                        Text(
                            text = "Wallet Balance",
                            color = Color.White,
                            fontSize = 12.sp,
                            modifier = Modifier.padding(top = 4.dp)
                        )
                    }
                }
            }

            Spacer(modifier = Modifier.width(16.dp))

            // Selected Recharge Amount
            Column(
                modifier = Modifier.fillMaxWidth(),
                horizontalAlignment = Alignment.CenterHorizontally // Center horizontally
            ) {
                Row(
                    verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier.padding(top = 20.dp)
                ) {
                    Text(
                        text = "${initializeCurrency()} ",
                        fontWeight = FontWeight.Bold,
                        fontSize = 20.sp,
                        color = Color.Black
                    )
                    Text(
                        text = (selectedRechargeAmount?.amount ?: 0).toString(),
                        fontSize = 24.sp,
                        fontWeight = FontWeight.Bold,
                        color = Color.Black
                    )
                }

                Text(text = "Select Recharge Amount", color = Color.Black, fontSize = 12.sp)
                Spacer(modifier = Modifier.width(16.dp))
            }

            Text(
                text = "Recommend Recharge Pack",
                fontWeight = FontWeight.Bold,
                modifier = Modifier.padding(top = 15.dp, bottom = 15.dp)
            )
            Spacer(modifier = Modifier.width(8.dp))
            // Recommended Recharge Packs List
            var selectedIndex by remember { mutableStateOf(-1) }
            if (recommendedRechargePacks.isEmpty()) {
                Text(
                    text = "No Recharge Pack Found!",
                    fontSize = 12.sp,
                    modifier = Modifier.padding(top = 12.dp).background(color = Color.LightGray)
                )
            } else {
                LazyVerticalGrid(
                    columns = GridCells.Fixed(2),
                    horizontalArrangement = Arrangement.spacedBy(16.dp),
                    verticalArrangement = Arrangement.spacedBy(16.dp),
                    modifier = Modifier.fillMaxSize()
                ) {
                    itemsIndexed(recommendedRechargePacks) { index, rechargePack ->
                        RechargePackItem(
                            rechargeData = rechargePack,
                            isSelected = index == selectedIndex, // Check if this card is selected
                            onItemClick = {
                                selectedIndex = index
                                selectedRechargeAmount = rechargePack // Update selected index when this card is clicked
                            }
                        )
//                        RechargePackItem(rechargeData = rechargePack,0,) {
//                            selectedRechargeAmount = rechargePack
//                        }
//                        Divider()
                    }
                }
            }
        }
        Spacer(modifier = Modifier.height(20.dp))

        // Recharge Button
        Button(onClick = {
            onRechargeButtonClick.invoke(selectedRechargeAmount)
        },
            modifier = Modifier.align(Alignment.BottomCenter)
                .fillMaxWidth(), // Align button at the bottom center of the screen
//                .padding(16.dp),
            colors = ButtonDefaults.buttonColors(primaryColor),
            shape = RoundedCornerShape(4.dp),
            elevation = ButtonDefaults.elevatedButtonElevation(3.dp),
            contentPadding = PaddingValues(horizontal = 8.dp),
            content = {
                Row(verticalAlignment = Alignment.CenterVertically) {
                    Text(
                        text = "Let's Recharge",
                        color = Color.White,
                        maxLines = 1,
                        textAlign = TextAlign.Center
                    )
                }
            })

        Spacer(modifier = Modifier.height(20.dp))
    }
}


//@Composable
//fun RechargePackItem(
//    rechargeData: RechargeData, onItemClick: () -> Unit
//) {
//    Card(
//        colors = CardDefaults.cardColors(Color.White),
//        modifier = Modifier.height(100.dp).fillMaxWidth()
//            .clickable(onClick = onItemClick),
//        elevation = CardDefaults.cardElevation(Scale.ELEVATION_SMALL)
//    ) {
//        Column(
//            modifier = Modifier.fillMaxSize(),
//            horizontalAlignment = Alignment.CenterHorizontally,
//            verticalArrangement = Arrangement.Top
//        ) {
//            rechargeData.cupon?.let { cupon ->
//                cupon.cuponText.takeIf { it?.isNotEmpty() == true }?.let { description ->
//                    Box(
//                        modifier = Modifier.fillMaxWidth().background(Color(0xFFEF5350)),
//                        contentAlignment = Alignment.Center
//                    ) {
//                        Text(
//                            text = description,
//                            fontSize = 13.sp,
//                            fontWeight = FontWeight.Bold,
//                            color = Color.White,
//                            textAlign = TextAlign.Center,
//                            modifier = Modifier.padding(vertical = 4.dp, horizontal = 10.dp)
//                        )
//                    }
//                }
//            }
//
//            Row(
//                verticalAlignment = Alignment.CenterVertically,
//                horizontalArrangement = Arrangement.Center, // Center content horizontally within the Row
//                modifier = Modifier.fillMaxWidth().padding(16.dp).weight(1f),
//            ) {
//                Text(
//                    text = currency,
//                    fontWeight = FontWeight.Bold,
//                    fontSize = 20.sp,
//                    modifier = Modifier.padding(end = 2.dp)
//                )
//                Text(
//                    text = rechargeData.amount.toString(),
//                    fontWeight = FontWeight.Bold,
//                    fontSize = 20.sp,
//                    color = Color.Black
//                )
//            }
//        }
//    }
//}

@Composable
fun RechargePackItem(
    rechargeData: RechargeData,
    isSelected: Boolean,
    onItemClick: () -> Unit
) {
    // Animate border thickness and color based on the selection state
    val borderColor by animateColorAsState(targetValue = if (isSelected) primaryColor else Color.Transparent)
    val borderWidth by animateDpAsState(targetValue = if (isSelected) 2.dp else 0.dp)

    Card(
        colors = CardDefaults.cardColors(Color.White),
        modifier = Modifier
            .height(100.dp)
            .fillMaxWidth()
            .border(
                width = borderWidth, color = borderColor, shape = RoundedCornerShape(Scale.CORNER_EXTRA_SMALL)
            )
            .clickable { onItemClick() }, // Handle the click event
        elevation = CardDefaults.cardElevation(Scale.ELEVATION_LARGE)
    ) {
        Column(
            modifier = Modifier.fillMaxSize(),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Top
        ) {
            rechargeData.cupon?.let { cupon ->
                cupon.cuponText.takeIf { it?.isNotEmpty() == true }?.let { description ->
                    Box(
                        modifier = Modifier
                            .fillMaxWidth()
                            .background(Color(0xFFEF5350)),
                        contentAlignment = Alignment.Center
                    ) {
                        Text(
                            text = description,
                            fontSize = 13.sp,
                            fontWeight = FontWeight.Bold,
                            color = Color.White,
                            textAlign = TextAlign.Center,
                            modifier = Modifier.padding(vertical = 4.dp, horizontal = 10.dp)
                        )
                    }
                }
            }

            Row(
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Center, // Center content horizontally within the Row
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(16.dp)
                    .weight(1f),
            ) {
                Text(
                    text = initializeCurrency(),
                    fontWeight = FontWeight.Bold,
                    fontSize = 20.sp,
                    modifier = Modifier.padding(end = 2.dp)
                )
                Text(
                    text = rechargeData.amount.toString(),
                    fontWeight = FontWeight.Bold,
                    fontSize = 20.sp,
                    color = Color.Black
                )
            }
        }
    }
}

